<template>
	<div style="padding:15px;">
		<div style="padding: 15px;background-color: #fff;margin-top: 10px;">
			<div class="newsf">
				<div class="newsitem" v-for="(item,index) in newslist">
					<el-image class="newsimg" :src="item.imgurl" fit="fill"></el-image>
					<div class="newsitem_con">
						<h4>{{item.title}}</h4>
						<div class="newsitem_con_2">
							<div>{{item.updatatime}}</div>
							<div>
								<el-button type="primary" icon="el-icon-edit" circle @click="updata(item.id)"></el-button>
								<el-button type="danger" icon="el-icon-delete" circle @click="del(item.id)"></el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Vue from 'vue'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	export default Vue.extend({
		components: {
			Editor,
			Toolbar
		},
		data() {
			return {
				newslist:[],
				keyword:'',
				page:'',
				pagetotal:1,
				activeName:''
			}
		},
		mounted() {
			// 模拟 ajax 请求，异步渲染编辑器
			this.getdata()
		},
		methods: {
			getdata(keyword = ''){
				this.$get("/index/getkronoswiper").then(res2=>{
					this.newslist = res2.data
				})
			},
			updata(id){
				// 编辑新闻
				this.$router.push({ path: `updatabanner/${id}` })
			},
			del(id){
				// 删除新闻
				this.$confirm('确定要删除吗','提示',{
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then((res1)=>{
					if(res1 == 'confirm'){
						this.$post("/index/delkronoswiper",{
							id:id
						}).then(res=>{
							this.$message({
								type: 'success',
								message: '已删除'
							});
							
							this.getdata()
						})
					}
				})
			}
		},
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		}
	})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
	.newsf{
		width: 98%;
		border-bottom: 2px solid #ebebeb;
		padding-bottom: 15px;
		margin: 15px auto;
	}
	.newsitem{
		display: flex;
	}
	.newsitem_con_2{
		display: flex;
		margin-top: 30px;
		justify-content: space-between;
	}
	.newsitem_con_2 div{
		font-size: 12px;
		color: #999;
		line-height: 30px;
	}
	.newsimg{
		width: 80px;
		height:80px;
		border-radius: 10px;
		margin-right: 20px;
	}
	.newsitem_con{
		width: 800px;
	}
	.newsitem_con_1{
		font-size: 14px;
		line-height: 22px;
	}
	.newsitem_con h4{
		margin-bottom: 10px;
	}
	.avatar-uploader .el-upload {
		border: 1px dashed #a5a5a5;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>